<template>
  <div class="users-content pt-4">
    <custom-scrollbar>
      <b-container fluid="xl">
        <div class="h3 mb-4">
          Пользователи
        </div>
        <b-row class="mb-2">
          <b-col class="w-100">
            <b-input-group class="bg-white rounded">
              <b-form-input
                v-model="filter.search"
                type="search"
                placeholder="Введите логин / фио / email пользователя"
                class="border-0"
                debounce="300"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  class="rounded-0 border-0"
                  @click="usersGet"
                >
                  <b-icon icon="search" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col sm="auto">
            <b-button
              variant="primary"
              @click="openNewUserModal"
            >
              <b-icon icon="plus" />
              Создать пользователя
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="4">
            <div class="mb-1 font-weight-bold">
              Выберите роль
            </div>

            <b-form-select
              v-model="filter.roleId"
              value-field="id"
              text-field="name"
              :options="filteredRoles"
              :disabled="disabledFields.roles"
              size="sm"
            >
              <b-form-select-option
                v-if="
                  !onlyMedicalWorker
                  && !onlyGovAdminSoft
                  && !onlyGovAdminRegion
                  && !onlyGovExtraAdminMoFull
                  && !onlyGovStaffDevice
                "
                :value="null"
              >
                Все роли
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <!-- v-if="!checkRoleAccess_(['Dis.monitoring.admin'])" -->
          <b-col
            v-if="checkFeatureAccess({ name: 'Поиск. Авторизация по ЕСИА', url: '/users' })"
            cols="4"
          >
            <div class="mb-1 font-weight-bold">
              Авторизация по ЕСИА
            </div>

            <b-form-radio-group
              v-model="onlyEsiaAuthorized"
              :options="authorizedTypes"
              value-field="value"
              text-field="title"
              @input="usersGet"
            />
          </b-col>
        </b-row>
        <template v-if="!isPageLoading && filteredUsers.length">
          <b-table
            :fields="fields"
            :items="filteredUsers"
            :busy="isBusy"
            :per-page="perPage"
            :current-page="currentPage"
            class="bg-white crm-table"
            bordered
            hover
            :small="true"
          >
            <!-- :items="users" -->
            <!-- :filter="filter.search" -->
            <!-- @filtered="onFiltered" -->
            <template #cell(actions)="row">
              <b-button
                v-b-tooltip.hover
                class="contact-legal-delete mr-2"
                variant="primary"
                size="sm"
                title="Редактировать"
                @click="openEditUserModal(row)"
              >
                <b-icon
                  icon="pencil-fill"
                />
              </b-button>
              <b-button
                v-b-tooltip.hover
                class="contact-legal-delete"
                variant="danger"
                size="sm"
                title="Удалить"
                @click="openDeleteModal(row)"
              >
                <b-icon
                  icon="x"
                  font-scale="1.5"
                />
              </b-button>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2" />
                <strong>Загрузка...</strong>
              </div>
            </template>
          </b-table>
          <div
            class="mt-3"
          >
            <div class="crm-pagination">
              <b-dropdown
                id="dropdown-1"
                :text="`${perPage}`"
                split
                class="m-md-2"
                size="sm"
                split-variant="outline-primary"
                variant="primary"
              >
                <b-dropdown-item @click="tableSizeHandler(10)">
                  10
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="tableSizeHandler(25)">
                  25
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="tableSizeHandler(50)">
                  50
                </b-dropdown-item>
              </b-dropdown>
              <b-pagination
                v-model="currentPage"
                pills
                :total-rows="rows"
                :per-page="perPage"
                style="padding-top: 16px"
              />
            </div>
          </div>
        </template>

        <template v-if="!isPageLoading && !filteredUsers.length">
          Не найдено
        </template>

        <template v-if="isPageLoading">
          <div class="d-flex justify-content-center mt-5">
            <b-spinner
              label="Loading..."
              variant="primary"
              style="width: 5rem; height: 5rem;"
            />
          </div>
        </template>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixinRoles } from '@/mixins';
import * as types from '@/store/types';

export default {
  name: 'Users',
  page: {
    title: 'CRM Doctis - Пользователи',
  },
  mixins: [mixinRoles],
  data() {
    return {
      isPageLoading: false,
      fields: [
        { key: 'displayName', label: 'ФИО', sortable: true },
        { key: 'login', label: 'Логин', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'actions', label: '' },
      ],
      userData: null,
      currentPage: 1,
      perPage: 10,
      filter: {
        search: null,
        roleId: null,
      },
      rows: 1,
      roles: [],
      authorizedTypes: [
        { value: false, title: 'Нет' },
        { value: true, title: 'Да' },
      ],
      onlyEsiaAuthorized: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: types.IS_LOADING_GET,
      fetchUsersTrigger: types.FETCH_USERS_TRIGGER,
    }),
    onlyMedicalWorker() {
      // this.checkRoleAccess_(['Dis.monitoring.admin']);
      return this.checkFeatureAccess({ name: 'Видеть только роль medical.worker.dis.monitoring', url: '/users' });
    },
    onlyClinicAdministratorInRoles() {
      // this.checkRoleAccess_('Operator.gostelemed')
      return this.checkFeatureAccess({ name: 'В фильтре только роль ClinicAdministrator', url: '/users' });
    },
    onlyGovAdminSoft() {
      return this.checkFeatureAccess({ name: 'Видеть только роль gov.admin.soft', url: '/users' });
    },
    onlyGovAdminRegion() {
      return this.checkFeatureAccess({ name: 'Видеть только роль gov.admin.region', url: '/users' });
    },
    onlyGovExtraAdminMoFull() {
      return this.checkFeatureAccess({ name: 'Видеть только роль gov.extra.admin.mo.full', url: '/users' });
    },
    onlyGovStaffDevice() {
      return this.checkFeatureAccess({ name: 'Видеть только роль gov.staff.device', url: '/users' });
    },
    users() {
      return this.$store.state.Users.users;
    },
    user() {
      return this.$store.state.Auth.user;
    },
    filteredRoles() {
      if (this.onlyMedicalWorker) {
        return this.roles.filter((item) => item.name === 'medical.worker.dis.monitoring');
      }
      if (this.onlyGovAdminSoft) {
        return this.roles.filter((item) => item.name === 'gov.admin.soft');
      }
      if (this.onlyGovAdminRegion) {
        return this.roles.filter((item) => item.name === 'gov.admin.region');
      }
      if (this.onlyGovExtraAdminMoFull) {
        return this.roles.filter((item) => item.name === 'gov.extra.admin.mo.full');
      }
      if (this.onlyGovStaffDevice) {
        return this.roles.filter((item) => item.name === 'gov.staff.device');
      }
      return this.roles;
    },
    filteredUsers() {
      let users = this.users.filter((user) => {
        if (this.filter.search != null) {
          const searchString = `${this.filter.search}`.toLowerCase();
          const vals = Object.keys(user).map((key) => `${user[key]}`.toLowerCase());
          const validity = vals.some((val) => val.includes(searchString));
          if (!validity) return false;
        }

        if (this.filter.roleId != null) {
          const validity = this.filter.roleId === user.roleId;
          if (!validity) return false;
        }

        return true;
      });

      // this.checkRoleAccess_(['Dis.monitoring.admin'])
      if (users.length && this.checkFeatureAccess({ name: 'Видеть юзеров только с общими клиниками', url: '/users' })) {
        // есть ли совпадения по id клиник
        // eslint-disable-next-line max-len
        users = users.filter((item) => item.clinicsAdministration?.some((clinicItem) => this.user.clinicAdminIds?.includes(clinicItem)));
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.rows = users.length; this.currentPage = 1;

      return users;
    },
    isBusy() {
      return this.isLoading;
    },
    disabledFields() {
      return {
        roles: this.onlyClinicAdministratorInRoles || false,
      };
    },
  },

  watch: {
    async fetchUsersTrigger() {
      this.isPageLoading = true;

      try {
        await this.usersGet();
        this.rows = this.users.length;
      } catch (e) {
        console.error(e);
      } finally {
        this.isPageLoading = false;
      }
    },
  },

  async created() {
    try {
      this.roles = await this.$store.dispatch(this.$types.ROLES_FETCH);

      if (this.onlyMedicalWorker) {
        const actualRole = this.roles.find((item) => item.name === 'medical.worker.dis.monitoring');
        if (actualRole) {
          this.filter.roleId = actualRole.id;
        }
      }
      if (this.onlyGovAdminSoft) {
        const actualRole = this.roles.find((item) => item.name === 'gov.admin.soft');
        if (actualRole) {
          this.filter.roleId = actualRole.id;
        }
      }
      if (this.onlyGovAdminRegion) {
        const actualRole = this.roles.find((item) => item.name === 'gov.admin.region');
        if (actualRole) {
          this.filter.roleId = actualRole.id;
        }
      }
      if (this.onlyGovExtraAdminMoFull) {
        const actualRole = this.roles.find((item) => item.name === 'gov.extra.admin.mo.full');
        if (actualRole) {
          this.filter.roleId = actualRole.id;
        }
      }
      if (this.onlyGovStaffDevice) {
        const actualRole = this.roles.find((item) => item.name === 'gov.staff.device');
        if (actualRole) {
          this.filter.roleId = actualRole.id;
        }
      }
    } catch (e) {
      console.error(e);
    }
  },
  async mounted() {
    this.isPageLoading = true;
    try {
      await this.usersGet();
      this.rows = this.users.length;

      if (this.onlyClinicAdministratorInRoles) {
        const clinicAdminRole = this.roles.find((role) => role.name === 'ClinicAdministrator');
        this.filter.roleId = clinicAdminRole.id;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isPageLoading = false;
    }
  },
  methods: {
    tableSizeHandler(mount) {
      this.perPage = mount;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async usersGet() {
      await this.$store.dispatch(this.$types.GET_USERS, {
        onlyEsiaAuthorized: this.onlyEsiaAuthorized,
      });
    },
    openNewUserModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'UserViewModal' });
    },
    openEditUserModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'UserViewModal', props: { currentUserData: row } });
    },
    openDeleteModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'UserDeleteModal', props: { currentUserData: row } });
    },
    filterRole(role) {
      return this.roles.filter((item) => item.name === role);
    },
  },
};
</script>

<style lang="scss" scoped>

.users-content {
  height: 100vh;
  width: 100%;
  transition: height 0.3s linear;
}

.crm-table {
  overflow: hidden;
}

.crm-pagination{
  display: flex;
  align-items: center;
}

.crm-icon-delete {
  cursor: pointer;
  transition: .3s all;
  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}
.crm-icon-edit {
  cursor: pointer;
  transition: .3s all;
  path {
    fill: $blue;
  }
  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}
</style>
